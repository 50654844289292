import React, { Component, useRef  } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import { v4 as uuidv4 } from "uuid";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {addUser} from '../../config/firebase';
import emailjs from 'emailjs-com'

import ScriptTag from 'react-script-tag';
<script type="text/javascript" src="https://checkout.wompi.co/widget.js"></script>


class Signup extends Component {

  

  //Todos lo que acceden o modifican el estado se inicializan el constructor
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); 
    this.state={nombre:"",cedula:"",email:"", telefono:"", ciudad:""}
    this.setName=this.setName.bind(this);
    this.setCedula=this.setCedula.bind(this);
    this.setEmail=this.setEmail.bind(this);
    this.setTelefono=this.setTelefono.bind(this);
    this.setCiudad=this.setCiudad.bind(this);
    //this.handleSubmit=this.handleSubmit.bind(this);
    this.handleSubmit2=this.handleSubmit2.bind(this);
    this.generarRef();
  }

  

  setName(event) {
    this.setState({nombre:event.target.value});
    
  }

  setCedula(event) {
    this.setState({cedula:event.target.value});
    
  }

  setEmail(event) {
    this.setState({email:event.target.value});
    
  }

  setTelefono(event) {
    this.setState({telefono:event.target.value});
    
  }

  setCiudad(event) {
    this.setState({ciudad:event.target.value});
    
  }


  handleSubmit2(event) {

    event.preventDefault();   
    
    if(this.state.nombre !== "" && this.state.cedula !== "" && this.state.email !== "" && this.state.telefono !== "" && this.state.ciudad !== "" ){
        //Aqui se agrega a la base de datos
        let obj = {nombre:this.state.nombre, cedula:this.state.cedula, email:this.state.email, telefono:this.state.telefono, ciudad:this.state.ciudad}
        addUser(obj);
        window.alert("Usuario añadido!, por favor proceda con el pago para activar su membresía");
        //this.props.history.push("/");

        //Aqui lo envia al correo
        emailjs.sendForm('service_8f2pzol', 'template_8ww5cog', this.myRef.current,'user_F1XblGGWPnBTc6I63JiQ2')
        .then(res=>{
          console.log(res);
        }).catch(error=>{console.log(error)});
    }else{
      window.alert("Por favor llena todos los campos");
    }

  }
  

  /* handleSubmit(event) {

    event.preventDefault();   
    
    //Aqui se agrega a la base de datos
    let obj = {nombre:this.state.nombre, cedula:this.state.cedula, email:this.state.email, telefono:this.state.telefono, ciudad:this.state.ciudad}
    addUser(obj);
    window.alert("Usuario añadido!, porfavor proceda con el pago para activar su membresia");
    //this.props.history.push("/");

    //Aqui lo envia al correo
    emailjs.sendForm('service_8f2pzol', 'template_8ww5cog', this.myRef.current,'user_F1XblGGWPnBTc6I63JiQ2')
    .then(res=>{
      console.log(res);
    }).catch(error=>{console.log(error)});
    

  } */

  //Genera la referencia unica de pago
  generarRef(){
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        var string_length = 32;
        var randomstring = '';
        
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }
        
        return randomstring; 
  }


  render() {
    
    return (
      
      <React.Fragment>
        <div className="account-home-btn d-none d-sm-block ">
          <Link to="/" className="text-white">
            <i className="mdi mdi-home h1"></i>
          </Link>
        </div>

        <section className=" bg-signUp  vh-100 "
        
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={5}>
                    <Card className="account-card">
                      <CardBody>
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold">
                            <Link
                              to="/"
                              className="text-dark text-uppercase account-pages-logo"
                            >
                              Sagrada Congregación Equina Internacional
                            </Link>
                          </h3>
                          <p className="text-muted">
                            Inscríbete
                          </p>
                        </div>
                        <div className="p-3">

                        <AvForm >

                        <form ref={this.myRef} >

                        <FormGroup>
                        <Label htmlFor="firstname" >Nombre</Label>
                          <input 
                          className="form-control"
                          type="text" 
                          name="name" 
                          id="nombre"
                          required
                          
                          placeholder="Ingresa un nombre"
                          onChange={this.setName}/>

                        </FormGroup>

                        <FormGroup>
                        <Label htmlFor="cedula">Cedula</Label>
                          <input 
                          
                          className="form-control"
                          type="text" 
                          name="cedula"
                          id="cedula"
                          required
                          placeholder="Ingresa tu cedula"
                          onChange={this.setCedula} />

                        </FormGroup>

                        <FormGroup>
                        <Label htmlFor="email">Email</Label>
                          <input 
                          
                          className="form-control"
                          type="email" 
                          name="email"
                          id="email"
                          required
                          placeholder="Ingresa un email"
                          onChange={this.setEmail} />

                        </FormGroup>

                        <FormGroup>
                        <Label htmlFor="telefono">Telefono</Label>
                          <input 
                          
                          className="form-control"
                          type="telefono" 
                          name="telefono"
                          id="telefono"
                          required
                          placeholder="Ingresa su numero"
                          onChange={this.setTelefono} />

                        </FormGroup>

                        <FormGroup>
                        <Label htmlFor="ciudad">Ciudad</Label>
                          <input 
                          
                          className="form-control"
                          type="ciudad" 
                          name="ciudad"
                          id="ciudad"
                          required
                          placeholder="Ingresa su ciudad de residencia"
                          onChange={this.setCiudad} />

                        </FormGroup>

                        

    
                        </form>
                          

                            {/* <FormGroup>
                              <Label htmlFor="ciudad">Ciudad de residencia</Label>
                              <AvField 
                              type="ciudad"
                              name="ciudad"
                              required
                              className="form-control"
                              id="ciudad"
                              placeholder="Ingresa tu ciudad de residencia"
                              onChange={this.setCiudad} />
                              
                            </FormGroup> */}

                            

                            {/* <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="customControlInline"
                              />{" "}
                              <Label
                                className="custom-control-label"
                                htmlFor="customControlInline"
                              >
                                Recuerdame
                              </Label>
                            </div> */}

                            <div className="d-grid mt-3">
                              <Button
                                type="submit"
                                color="none"
                                className="btn btn-primary"
                                onClick={this.handleSubmit2}
                                
                              >Registrarme

                              </Button>
                            </div>

                          <div className="col-center">
                          <ScriptTag 
                          className="col-center"
                          src="https://checkout.wompi.co/widget.js"
                          data-render="button"
                          data-public-key="pub_prod_j6ahdaOgPskrNYOY8Ib84PFtr23LXpEc"
                          data-currency="COP"
                          data-amount-in-cents="7500000"
                          data-reference={this.generarRef()}/>

                          </div>

                            {/* <div className="mt-4 mb-0 text-center">
                              <p className="mb-0">
                                Don't have an account ?{" "}
                                <Link to="/login" className="text-danger">
                                  Sign in
                                </Link>
                              </p>
                            </div> */}
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Signup;
