import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TeamBox from "./TeamBox";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          image: "assets/images/team/img-1.jpg",
          title: "Gustavo Martinez",
          desc: "CEO-Pastor",
        },
        {
          image: "assets/images/team/img-2.jpg",
          title: "Sebastian Gutierrez",
          desc: "Diseñador",
        },
        {
          image: "assets/images/team/img-3.jpg",
          title: "Fabián Portilla",
          desc: "Desarrollador",
        },
        {
          image: "assets/images/team/img-4.jpg",
          title: "Joshua Stemple",
          desc: "MANAGER",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-4" id="team">
          <Container>
            {/* section title */}
            <SectionTitle
              title="NUESTRO EQUIPO"
              desc="Somos un grupo de fieles amantes de los caballos que lucha por el y promueve el respeto y admiración de este animal divino."
            />

            <Row className="mt-5">
              {/* team box */}
              <TeamBox teams={this.state.teams} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
