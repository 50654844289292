import Index1 from "./pages/Index1/Index1";

//Auth Pages
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import PasswordForget from "./pages/Auth/PasswordForget";
import HorseEvents from "./pages/Event/HorseEvents";
import {Route, Switch, Link, BrowserRouter  as  Router } from  'react-router-dom';


/* const routes = [
  { path: "/password-forget", component: PasswordForget },
  { path: "/login", component: Login },
  { path: "/sign-up", component: Signup },
  { path: "/events", component: HorseEvents },

  
  { path: "/home-one", component: Index1 },
  { path: "/", component: Index1 },
]; */

const  routing  = (
  <Router>
  
      <Switch>
          <Route exact path="/" component={Index1} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/sign-up" component={Signup} />
          <Route exact path="/events" component={HorseEvents} />
          
      </Switch>
  </Router>
)


export default routing;
