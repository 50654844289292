import React, { Component } from "react";
//import "../Event/StyleEvent"
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import ScriptTag from 'react-script-tag';
import {getUserId} from '../../config/firebase';



class HorseEvents extends Component {

    constructor() {
        super();
        this.setMemberId=this.setMemberId.bind(this);
        /* this.generarRef();
        this.validarId(); */
        this.state = {
          isOpen: false,
        };
        this.callModal.bind(this);
      }
    
      callModal = () => {
        this.refs.child.openModal();
      };

      setMemberId(event) {
        this.setState({memberId:event.target.value});
        
      }

      generarRef(){
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        var string_length = 32;
        var randomstring = '';
        
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }
        
        return randomstring; 
      }

      /* validarId(){

        if(this.state.memberId == )

      }

      changeDisabled(){
        disabled = false;
      } */

    render() {
        return (
          <React.Fragment>
          <div className="account-home-btn d-none d-sm-block ">
          <Link to="/" className="text-white">
            <i className="mdi mdi-home h1"></i>
          </Link>
          </div>
          <section className=" bg-Events  vh-100 "
        
        >


          <section class="section section-lg bg-default wow fadeIn">
            <div class="container">
              <div class="row row-30 justify-content-center">
                <div class="col-md-10 col-lg-6 col-xl-5">
                <h2 className="home-title">
                        Sobre el evento
                        </h2>
                  <h3 class="heading-lg-postfix-15">Lanzamiento de nuestra primera peregrinación.</h3>
                  <p>Asiste a nuestra inauguración, en el cual podrás disfrutar de cabalgatas y todos nuestros los beneficios.
                    
                  </p>
                  
                  
                  <ul class="list-inline list-inline-xl">

                  <Row className="mt-4">

                  <li>
                      <div class="unit">
                        <div class="unit-left">
                          <svg class="svg-icon-sm svg-icon-primary" role="img">
                            
                          </svg>
                        </div>
                        <div class="unit-body">
                          <h4>¿Donde y cuando?</h4>
                          <h6>Popayán - Sabado 4 de Diciembre del 2021</h6>
                          <h6>Valor de la inscripción: $70.000</h6>
                          
                        </div>
                      </div>
                    </li>
                    

                  
                  </Row>

                  </ul>


                  {/* Aqui se valida el id */}
                  {/* <FormGroup>
                        <Label htmlFor="cedula">ID member</Label>
                          <input 
                          
                          className="form-control"
                          type="text" 
                          name="cedula"
                          id="cedula"
                          required
                          placeholder="Ingresa tu codigo de membresía"
                          onChange={this.setMemberId}  />

                  </FormGroup>
                  <button btn btn-primary  onClick={this.validarId()} >Validar</button> */}


                  <button className="btn btn-wompi-event" disabled={false}>
                  <ScriptTag 
                          className="col-center"
                          src="https://checkout.wompi.co/widget.js"
                          data-render="button"
                          data-public-key="pub_prod_j6ahdaOgPskrNYOY8Ib84PFtr23LXpEc"
                          data-currency="COP"
                          data-amount-in-cents="1"
                          data-reference={this.generarRef()}
                          />

                  </button>
                  
                </div>


                <div class="col-md-10 col-lg-6  ">

                
                    
                  <div class="images-box">
                    <div class="images-box-item images-box-item-right">
                      <div class="wow fadeScale"><img src="assets/images/equino 4.jpg" alt="" width="900" height="595"/>
                      </div>
                    </div>
                    
                  </div> 
                </div>
              </div>
            </div>

          </section>


      </section>



























        {/* <section className="section-about bg" id="features">
          <Container>
            <Row className="vertical-content">
            <Col lg={7}>
                <div className="features-img features-right text-right">
                  <img
                    src="assets/images/logoCEI.png" 
                    alt="assets/images/logoCEI.png"
                    className="img-fluid bg-about"
                  /> 
                </div>
                <section
               className="section bg-about home-half vh-100 "
                //data-image-src="images/mainImage.jpeg"
              id="home"

          
              />
              </Col>
              <Col lg={5}>
                <div className="features-box">
                  
                  <p className="text-muted web-desc">
                  <h2>La iglesia: Sagrada Congregación Equina Internacional "CEI"</h2>
                  Es el resultado de un acuerdo de voluntades entre varios hombres y mujeres que tenemos 
                  la fortuna de poseer y ser los dueños de un ser divino el equino, 
                  que de acuerdo al llamado de nuestra fe, propusieron establecer lugares de culto y adoración 
                  en todo el territorio nacional y en el exterior, con el propósito de divulgar la fe 
                  cuyo poder se nos da al poseer una creación divina como el caballo, se efectúo el primer culto 
                  de adoración a nuestro Dios.
                  El caballo debe su divinidad por reputación a su inteligencia, carácter fuerte y resistencia 
                  sobresaliente, con su cabeza característica y la cola siempre en alto, el caballo es una de las 
                  especies mejor reconocidas en el mundo. Entre las mas antiguas ya que existe evidencia arqueológica 
                  de hace 4500 años de caballos lo que nos data que es la especie mas antigua viva que ha acompañado al 
                  hombre en su fe y sus victorias antes de cristo, después de cristo y en la actualidad.

                  </p>
                  
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">We put a lot of effort in design.</li>
                    <li className="">The most important ingredient of successful website.</li>
                    <li className="">Sed ut perspiciatis unde omnis iste natus error sit.</li>
                    <li className="">Submit Your Orgnization.</li>
                  </ul>
                  
                  <Link
                    to="#"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Saber más <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              
            </Row>
          </Container>
                
        </section>            */}




            </React.Fragment>
        )
    }




}

export default HorseEvents;

