import React, { Component } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Col } from "reactstrap";
import Signup from "../../pages/Auth/Signup"



class PricingBox extends Component {

 /*  toSignup =() => { 
    const {history} = this.props;
    if(history) history.push('/sign-up')
  }  */
  
    
  
  render() {
    //const { history } = this.props;

    
    
    return (
      
      <React.Fragment>
        
        {this.props.pricings.map((pricing, key) => (
          <Col lg={6} key={key} /* style={{textAlign: 'center'}} */ className="col-center">

            <div style={{textAlign: 'center'}}>
            <div 
              className={
                pricing.isActive
                  ? "text-center pricing-box bg-white hover-effect price-active"
                  : "text-center pricing-box hover-effect "
              }
              
            >
              <h4 className="text-uppercase">{pricing.title}</h4>
              <h1>${pricing.price}.000</h1>
              <h6 className="text-uppercase text-muted">
                Duración de: {pricing.duration}
              </h6>
              <div className="pricing-border"></div>
              <div className="plan-features mt-4">
                {pricing.features.map((feature, key) => (
                  <p key={key}>
                    {feature.title}:{" "}
                    <b className="text-primary">{feature.value}</b>
                  </p>
                ))}
              </div >
              
              {/* <Router>
              <Link
                to="/sign-up"
                className="btn btn-primary waves-effect waves-light mt-4"
                
              >
                Adquirir
              </Link>
                  <Switch>
                  <Route path="/sign-up" component={Signup} />
                  </Switch>

              </Router> */}

              <Link
                to="/sign-up"
                className="btn btn-primary waves-effect waves-light mt-4"
              >
                Adquirir
              </Link>


              
              <div onClick={this.toSignup}></div>
            </div>  

            </div>
           
          </Col>
        ))}
      </React.Fragment>
    );

  }
}

export default PricingBox;
