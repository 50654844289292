import React from "react";
import routes from "./routes";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Login from "./pages/Auth/Login";
import Index1 from "./pages/Index1/Index1";
import HorseEvents from "./pages/Event/HorseEvents";
import Signup from "./pages/Auth/Signup";

function App() {
  return (
    /* <React.Fragment>
      <Router>
        <Switch>
          {routes.map((route, idx) => (
            <Route path={route.path} component={route.component} key={idx} />
            
            
          ))}
        <Route exact path= "/" component= {Index1}/>
        <Route exact path="/login" component= {Login} />
        <Route exact path="/event" component= {HorseEvents} />
        <Route exact path="/sign-up" component= {Signup} />

        </Switch>

        
      </Router>
    </React.Fragment> */
    <div>
      {routes}
    </div>

    
  );
}

export default App;
