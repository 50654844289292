import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings: [
    
        {
          title: "Membresía",
          price: 75,
          duration: "Un año, a partir de la fecha de registro",
          isActive: true,
          features: [
            { title: "Cupo garantizado", value: "Peregrinaciones" },
            { title: "Cupo garantizado ", value: "Ferias equinas" },
            { title: "Cupo garantizado ", value: "Cabalgatas" },
            { title: "Cupo garantizado ", value: "Exhibiciones privadas" },
            { title: "ID", value: "Para subscribirse a eventos" },
          ],
        },
        /* {
          title: "ULTIMATE",
          price: 29.90,
          duration: "Mo",
          features: [
            { title: "Bandwidth", value: "100GB" },
            { title: "Onlinespace ", value: "2 GB" },
            { title: "Support", value: "Yes" },
            { title: "Domain", value: "Unlimited" },
            { title: "Hidden Fees", value: "No" },
          ],
        }, */
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-5" id="pricing">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Inscripción"
              desc="Adquiere tu membresía y disfruta de los beneficios este 6 de enero."
            />

            <Row className="mt-5">
              {/* pricing box */}
              <PricingBox pricings={this.state.pricings} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Pricing;
