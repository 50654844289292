import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import BlogBox from "./BlogBox";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [
        {
          image: "assets/images/Cuidado/horseCare.jpg",
          title: "Dando un paseo",
          desc:
            "Una de las mejores maneras de alabar al equino es dando un paseo sano con él, por eso promovemos de una manera sana, respetuosa y sobre todo responsable, la ejecución de cabalgatas",
        },
        {
          image: "assets/images/Cuidado/horseShoe.jpg",
          title: "Nueva exhibición en nuestra sede",
          desc:
            "Nnuestra sede ubicada en popayan cumple con los estandares estupilados por la asociación de proteccióna animal",
        },
        {
          image: "assets/images/Horizontales/horsesRanch.jpg",
          title: "Inuguración de peceras",
          desc:
            "Proximamente nuestras peceras serán remodeladas",
        },
      ],
    };
  }

  render() {
    
    return (

      
      <section className="section bg-comunicados" id="blog">

      

        <div>
        <div className="section-title-comunicados text-center"> COMUNICADOS</div>
          </div>    
        
        
        <div className="bg-overlay-comunicados"></div>
      
        <div className="bg-pattern-effect">
        <img src="assets/images/bg-pattern.png" alt="dorsin" />
      </div> 
        
        <Container>
          {/* section title */}
          {/* <SectionTitle 
            title="COMUNICADOS"
            desc="ENTERATE DE NUESTRAS ULTIMAS NOTICIAS."
          />  */}
          

          <Row className="mt-4">
            {/* blog box */}
            <BlogBox blogs={this.state.blogs} />
          </Row>
        </Container>
      </section>
    );
  }
}

export default Blog;
