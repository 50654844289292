import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';  
//import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'; 

class Social extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="contact-social bg-light">
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <ul className="list-inline social mt-4">

                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/Sagrada-congregaci%C3%B3n-equina-100206912423356" className="social-icon">
                    <i className="mdi mdi-facebook"></i>
                      </a>
                  </li>

                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/sce_internacional/" className="social-icon">
                    <i className="mdi mdi-instagram"></i>
                      </a>
                  </li>

                  <li className="list-inline-item">
                    <a href="https://youtu.be/k4B3L_LLYhY" className="social-icon">
                    <i className="mdi mdi-youtube"></i>
                      </a>
                  </li>
                  
                  {/* <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-twitter"></i>
                    </Link>
                  </li>

                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-instagram"></i>
                    </Link>
                  </li> */}
                 
                </ul>
              </Col>
              <Col  className="mt-4">
                <p className="contact-title">
                  <i className="pe-7s-call"></i> &nbsp;+57 311 438 0614
                </p>
              </Col>
              <Col  className="text-right">
                <p className="contact-title">
                  <i></i>&nbsp; Congregaciónequina@gmail.com
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

Social.propTypes={
  Url: PropTypes.string
}

export default Social;
