import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TestimonialsBox from "./TestimonialsBox";

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [
        {
          image: "assets/images/testimonials/user-1.jpg",
          title: "Luis Carlos Torres",
          subTitle: "Feligrés",
          desc:
            "Estoy impresionado con la labor de esta congregación por proteger la integridad fisica y mental de los caballos. Llena de orgullo saber que hay buenas maneras de admirar este animal en las cabalgatas y congregaciones",
        },
        {
          image: "assets/images/testimonials/user-2.jpg",
          title: "Mariana Posada",
          subTitle: "Feligresa",
          desc:
            "Mi animal favorito es el caballo, y esta congregación es la manera perfecta de disfrutar de este bello animal que nos ha regalado Dios",
        }
        ,
        {
          image: "assets/images/testimonials/user-3.jpg",
          title: "Efraín Angulo",
          subTitle: "Feligrés",
          desc:
            "Es un orgullo que aún haya gente que se preocupe por conservar la tradición, las cabalgatas deben perdurar para toda la vida y esta congregación lo está haciendo posible",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="testi">
          <Container>
            {/* section title */}
            <SectionTitle
              title="TESTIMONIOS"
              desc="Nuestros feligreces cuentan su opinión sobre su experiencia en esta congregación."
            />

            <Row className="mt-5">
              <TestimonialsBox clients={this.state.clients} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonials;
