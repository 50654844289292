import React, { Component } from "react";
import { Col, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import {Button, Modal, ModalHeader, ModalFooter} from 'reactstrap';

class BlogBox extends Component {

  constructor(props) {
    super(props);
    this.abrirModal=this.abrirModal.bind(this);

  }

  state={
    abierto: false
  }

  abrirModal=()=>{
    this.setState({abierto: !this.state.abierto})
  }



  render() {
    return (
      <React.Fragment>
        {this.props.blogs.map((blog, key) => (
          <Col key={key} lg={4}>
            <div className="blog-box mt-4 hover-effect">
              <img src={blog.image} className="img-fluid" alt="blog" />
              <div>
                <h5 className="mt-4 ">{blog.category}</h5>
                <h4 className="mt-3">
                  <Link to="#" className="blog-title">
                    {" "}
                    {blog.title}{" "}
                  </Link>
                </h4>
                <p className="text-muted">{blog.desc}</p>
                <div className="mt-3">
                  <Link to="#" className="read-btn" onClick={this.abrirModal}>
                    Leer más <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        ))}
        <Modal isOpen={this.state.abierto}>
          <ModalHeader>
            Noticia
          </ModalHeader>

          <ModalBody>

            {/* <div class="col-lg-2 col-md-2 col-sm-2 form-group">

              <img src="https://yt3.ggpht.com/-UbOeZ4ggXeI/AAAAAAAAAAI/AAAAAAAAAAA/hBtwXdntzws/s88-c-k-no-mo-rj-c0xffffff/photo.jpg" alt="imageAlt" class="image-responsive imagen" title="Cocodrilo"></img>
            </div> */}
            Asiste a nuestro proximo evento en:
        
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={this.abrirModal}>Cerrar</Button>
          </ModalFooter>

        </Modal>

      </React.Fragment>


    );
  }
}

export default BlogBox;
