import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";


class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          id: 1,
          icon: "pe-7s-map-2",
          title: "Eventos",
          desc:"Inscribete a nuestros eventos",
        },
        {
          id: 2,
          icon: "pe-7s-shopbag",
          title: "PRODUCTOS",
          desc:"Conoce nuestra sección de productos",
        },
        {
          id: 3,
          icon: "pe-7s-magnet",
          title: "EJEMPLARES",
          desc:"Echale un vistazo a nuestros equinos",
        },
      ]
      /* services2: [
        {
          icon: "pe-7s-science",
          title: "Awesome Support",
          desc:
            "It is a paradisematic country, in which roasted parts of sentences fly into your mouth leave for the far World.",
        },
        {
          icon: "pe-7s-news-paper",
          title: "Truly Multipurpose",
          desc:
            "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.",
        },
        {
          icon: "pe-7s-plane",
          title: "Easy to customize",
          desc:
            "Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. She packed her seven versalia.",
        },
      ], */
    };
  }

  toEnvent(){

  }


  render() {
    return (
      <React.Fragment>
        <section className={"section bg-light" + this.props.sectionClass} id="services" >
          <Container>
            {/* section title */}
            <SectionTitle
              title="Nuestros servicios"
              desc="Nuesta finalidad es predicar y enseñar el respeto, la fidelidad y el amor por el equino (caballo) en todo el mundo, para       establecer el Reino del equino (caballo) y recuperar lo perdido: principios éticos y morales para la Tierra y Gente.
              Para desarrollar sus fines puede establecer lugares de culto en todo el territorio nacional y en el exterior.
              "
            />

              {/* services box */}
              <Link
                to="/events"
              >
                <Row className="mt-4">
                <ServiceBox services={this.state.services1}/>
                </Row>
              </Link>
              
            

            

            {/* <Row className="mt-4">
              <ServiceBox services={this.state.services2} />
            </Row> */}
            
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
