import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import SectionTitle from "../common/section-title";

class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section-about bg" id="features">
          <Container>
            <Row className="vertical-content">
            <Col lg={7}>
                {/* <div className="features-img features-right text-right">
                  <img
                    src="assets/images/logoCEI.png" 
                    alt="assets/images/logoCEI.png"
                    className="img-fluid bg-about"
                  /> 
                </div> */}
                <section
               className="section bg-about home-half vh-100 "
                //data-image-src="images/mainImage.jpeg"
          id="home"

          
              />
              </Col>
              <Col lg={5}>
                <div className="features-box">
                  
                <SectionTitle
                title="Sobre nosotros"
                />
                  <p className="text-muted web-desc">
                  <h2>La iglesia: Sagrada Congregación Equina Internacional "CEI"</h2>
                  Es el resultado de un acuerdo de voluntades entre varios hombres y mujeres que tenemos 
                  la fortuna de poseer y ser los dueños de un ser divino el equino, 
                  que de acuerdo al llamado de nuestra fe, propusieron establecer lugares de culto y adoración 
                  en todo el territorio nacional y en el exterior, con el propósito de divulgar la fe 
                  cuyo poder se nos da al poseer una creación divina como el caballo, se efectúo el primer culto 
                  de adoración a nuestro Dios.
                  El caballo debe su divinidad por reputación a su inteligencia, carácter fuerte y resistencia 
                  sobresaliente, con su cabeza característica y la cola siempre en alto, el caballo es una de las 
                  especies mejor reconocidas en el mundo. Entre las mas antiguas ya que existe evidencia arqueológica 
                  de hace 4500 años de caballos lo que nos data que es la especie mas antigua viva que ha acompañado al 
                  hombre en su fe y sus victorias antes de cristo, después de cristo y en la actualidad.

                  </p>
                  
                  {/* <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">We put a lot of effort in design.</li>
                    <li className="">The most important ingredient of successful website.</li>
                    <li className="">Sed ut perspiciatis unde omnis iste natus error sit.</li>
                    <li className="">Submit Your Orgnization.</li>
                  </ul> */}
                  
                  <Link
                    to="#"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Saber más <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
