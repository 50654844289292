import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col} from "reactstrap";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";
import ReactPlayer from 'react-player';

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-half vh-100"
          //data-image-src="images/mainImage.jpeg"
          id="home"

          
        >
          
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={8} className="text-white text-center">
                    <h2 className="home-title">
                    Sagrada Congregación Equina Internacional 
                      <h1>Inscríbete a la primera peregrinación</h1>
                      
                    </h2>

                    {/* Para video*/}
                    <p className="play-shadow mt-4">
                      <Link
                        to="#"
                        onClick={this.callModal}
                        className="play-btn video-play-icon"
                      >
                        <i className="mdi mdi-play text-center"></i>
                      </Link>
                    </p>


                    {/*Cajon para el e-mail*/}
                    {/* <div className="text-center subscribe-form mt-5">
                      <Form>
                        <input type="text" placeholder="Email" required />
                        <Button
                          color="none"
                          type="submit"
                          className="btn-primary"
                        >
                          Subscribe
                        </Button>
                      </Form>
                    </div> */}
                    

                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          
         {/*Olas estaticas*/}
         <div className="bg-pattern-effect">
            <img src="assets/images/bg-pattern.png" alt="dorsin" />
          </div> 


          {/*Olas dinamicas*/}
          {/* <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div
                className="wave wave-one"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave1.png)`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-two">
              <div
                className="wave wave-two"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave2.png)`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-three">
              <div
                className="wave wave-three"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave3.png)`,
                }}
              ></div>
            </div>
          </div> */}


          {/* Render ModalSection Component for Modal */}
          <ModalSection ref="child" channel="youtube" videoId="ChkqTow0TEo" />
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
