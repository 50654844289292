import React, { Component } from "react";
import { Col } from "reactstrap";

class ServiceBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.services.map((service, key) => (
          <Col lg={4} key={key} className="mt-3">
            <div className="section services-box text-center hover-effect bg-cards">
              <i className={service.icon + " text-primary"}></i>
              <h4 className="title-cards">{service.title}</h4>
              <p className="pt-3 text-cards">{service.desc}</p>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default ServiceBox;
