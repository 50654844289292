import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from 'firebase/database';
import 'firebase/auth';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore'
//import 'firebase/collection'

import * as uuid from 'uuid';


// Your web app's Firebase configuration
const firebaseConfig = ({
  //version 30 dias
  apiKey: "AIzaSyCL5ESNd3HByG1fNc5zmR-s-rMx9Iq77es",
  authDomain: "scei-4f705.firebaseapp.com",
  projectId: "scei-4f705",
  storageBucket: "scei-4f705.appspot.com",
  messagingSenderId: "477791111322",
  appId: "1:477791111322:web:b34459937f8f86c7b7f723"

  //version normal
  /* apiKey: "AIzaSyCeB8TKEMWbzRO7yQUU8Y_Yu4NYCLOMTUQ",
  authDomain: "prodv-cei.firebaseapp.com",
  projectId: "prodv-cei",
  storageBucket: "prodv-cei.appspot.com",
  messagingSenderId: "651791463496",
  appId: "1:651791463496:web:59375d638459e3626e947d" */
});

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getDatabase();

//Metodo para obtener los usuarios de la base de datos
async function getUsersId(db){
  const users = collection(db, 'users/userId');
  const userSnapshot = await getDocs(users, db);
  const userList = userSnapshot.docs.map(doc => doc.data());
  return userList;
}

//metodo que es llamado para agregar los usuarios
const addUser = (arg) => {

    //id unico del usuario
    let userid = uuid.v1();

    //fecha de afiliaciom
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();
    let fecha = date + "/" + month + "/" + year + " " + hours + ":" + minutes;

    //fecha vencimiento de la afiliacion
    const calcularFechaVencimiento =()=>{
      let fechaFinal = fecha;
      var meses = 12;
      let mes = month * 1.0 + 1 + meses * 1.0;
      while(mes > 11){
          let dia = date;
          let theYear = year *1.0 + 1;
          mes = mes - 12;
          fechaFinal= new Date(theYear,mes,dia);
      }
      var fechaFin = fechaFinal + "/" + mes + "/" + fechaFinal;
      // Capturamos el elemento que la va a mostrar en el formulario
      /* var fecha_finElemento = document.getElementById("fecha_fin");
      // Le asginamos la fecha de fin al elemento span que muestra la fecha final en el formulario
      fecha_finElemento.innerHTML = fechaFin; */
      return fechaFin;
    }

    set(ref(db, 'users/'+ userid + fecha /*+  calcularFechaVencimiento() */), {
        /* vecimiento: calcularFechaVencimiento, */
        userId: userid,
        Registro: fecha,
        nombre: arg.nombre,
        cedula: arg.cedula,
        email: arg.email,
        telefono: arg.telefono,
        ciudad: arg.ciudad,
    });
  }

  export {db, addUser, getUsersId}